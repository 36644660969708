.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #121b42;
  z-index: 100;
  width: 100%; 
  padding-top: 20px;
  padding-bottom: 20px;

 
}

.header > h1{
color: White;
margin-left: 30px;
}


.header__icon {
  object-fit: contain;
  height: 100px;
  margin-left: 0px;
}

.header__right {
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
  width: 30vw;
  margin: right 80px;
  padding-right: 20px;
}
.header > Button {
  color: white;
  font-size: large;
  font-weight: 200;
 
  border-radius: 20px;
}

