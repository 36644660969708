.card {
  width: 280px;
  /*display: block;*/
  border-radius: 10px;
  overflow: hidden;
  margin: 60px auto;
  box-shadow: 10px 5px 40px 20px darken(#341cac, 5%);
  transition: transform 100ms ease ease-in-out;
  background-color: #eb9d45;
}

.card:hover {
  transform: scale(1.07);
}

.card > img {
  object-fit: fill;
  min-width: 50;
  min-height: 50;
  width: 60%;
  padding-top: 30px;
  padding-left: 30px;
}

.card__info {
  margin-top: -9px;
  border-radius: 10px;
  padding: 20px;
  padding-top: 20px;
  border: 1;
}

.card__info > h2 {
  font-size: 18px;
  font-weight: 600;
}

.card__info > h4 {
  font-size: 16px;
  font-weight: 300;
  margin-top: 15px;
  margin-bottom: 8px;
}
.card_price_get_acess {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_price_get_acess > Button {
  color: white;
  background-color: black;
  border-radius: 20px;
}
