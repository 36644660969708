.Banner {
  align-items: center;
}
.banner__image {
  width: 100%;
  height: 60vh;

  /*object-fit: contain;
  height: 300px;
  margin-left: 10px;
  margin-top: 50px;
  border: 1px;
  border-radius: 50px;*/
}

.banner_text {
  padding-left: 70px;
  padding-right: 30px;
  font-size: x-large;
}
