.minicards {
    display: flex;
    justify-content: space-between;
    padding: 40px;
  
  }

  .minicard > img {
    width: 40px;
    
  }