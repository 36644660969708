.tradeAssitance {
 position: absolute;
  padding: 10px;
  top: 35%;
  left: 70%;
  transform: translate(-50%, -50%);
  color: white;
  border-radius: 10px;
  background-color: rgba(132, 165, 197, 0.74);
  transition: transform 100ms ease ease-in-out;

}
