.servicepage {
  background-color: #121b42;;
}

.service_cards {
  padding-top: px;
  display: flex;
  align-items: center;
}

.servicepage > h2 {
 
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
}
