.home {
  margin: 0;
  background-color: #121b42;
}

.home_cards {
  padding-top: px;
  display: flex;
  align-items: center;
}

.home > h2 {
  margin-top: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
}

.banner_div > h1  {
  position: absolute;
  top: 20%;
  left: 20%;
  transform: translate(-50%, -50%);
  color: white;

}